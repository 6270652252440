import axios from 'axios';
import { useJobId } from '../contexts/ContextJbo';
import { useContext } from "react";
import { CurrencyContext } from "../contexts/CurrencyContext";
import { UserContext } from '../contexts/UserContext';
import { ThemeContext } from '../contexts/ThemeContext';
import { useRouter } from 'next/router'; // Import useRouter
import { BASE_URL } from './config'; // Import your BASE_URL config

// Utility function to get a cookie value by name
function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

// Function to format the date in Indian Standard Time (IST)
const getFormattedDate = () => {
  const now = new Date();

  // Convert to Indian Standard Time (IST)
  const options = {
    timeZone: 'Asia/Kolkata',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  };

  // Format the date to DD-MM-YYYY HH:MM:SS
  const formatter = new Intl.DateTimeFormat('en-IN', options);
  return formatter.format(now).replace(',', ''); // Remove comma between date and time
};

// Use a Set to track which requests have already triggered a log
const loggedRequests = new Set();

export function useAxios() {
  const { jboId } = useJobId(); // Use job ID context
  const theme = useContext(ThemeContext); // Use theme context
  const router = useRouter(); // Use router for capturing current route
  const { userId } = useContext(UserContext); // Use user context
  const { currentCurrency } = useContext(CurrencyContext); // Use currency context

  // Create an Axios instance
  const instance = axios.create({
    baseURL: BASE_URL,
    headers: {
      'Content-Type': 'application/json',
    },
    timeout: 60000, // Set timeout (60s)
  });

  instance.interceptors.request.use((config) => {
    if (typeof config.data !== 'string') {
      config.data = {
        data: JSON.stringify({
          ...config.data,
          client_id: jboId,
          jbo_id: jboId,
          user_id: userId,
          currency: currentCurrency,
        }),
      };
    }
    return config;
  }, (error) => {
    return Promise.reject(error);
  });

  instance.interceptors.response.use(response => {
    return response;
  }, async (error) => {
    const config = error.config;

    // Set the retry count if it's not already set
    if (!config.__retryCount) {
      config.__retryCount = 0;
    }

    // Create a unique identifier for the request based on method and URL
    const requestKey = `${config.method}-${config.url}`;

    // Ensure the log API is called only once for the same request
    if (!loggedRequests.has(requestKey)) {
      loggedRequests.add(requestKey);  // Mark request as logged

      // Check for network errors or server-side (5xx) errors
      const isNetworkError = error.code === 'ERR_NETWORK';
      const isTimeoutError = error.code === 'ECONNABORTED';
      const statusCode = error.response ? error.response.status : null;

      // Log the error to external logger API
      if ((isNetworkError || isTimeoutError || (statusCode >= 500 && statusCode < 600)) && !config.__logSent) {
        config.__logSent = true; // Prevent multiple log calls for the same request
        try {
          await axios.post('https://logger.jpencil.in/api/log', {
            method: config?.method?.toUpperCase() || 'UNKNOWN',
            baseURL: config?.baseURL || 'UNKNOWN',
            endpoint: config?.url || 'UNKNOWN',
            requestData: config?.data || 'UNKNOWN',
            statusCode: statusCode || 'Network Error', // Capture status code if available
            message: error.message,  // Log the network error message or response message
            date: getFormattedDate(), // Pass the formatted date
            source: 'Website',
            stack: error.stack || 'No stack available', // Log the stack trace for debugging
            routerPath: router.asPath, // Capture the current router path in Next.js
            jboId: jboId
          });
        } catch (logError) {
          console.error('Error logging network or server error:', logError);
        }
      }
    }

    // Retry the request if 502 and retry count is less than 2
    if (error.response && error.response.status === 502 && config.__retryCount < 2) {
      config.__retryCount += 1;

      // Retry the request with the same payload after a delay
      return await new Promise((resolve) => {
        setTimeout(() => {
          resolve(instance(config));
        }, 1000); // Add a delay before retrying (1 second)
      });
    }

    return Promise.reject(error);
  });

  return instance;
}
